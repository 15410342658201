//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { getAddress, conflux, Drip } from "../../utils/cfx";
export default {
  name: "SellMobile",
  data() {
    return {
      nft_url: "http://127.0.0.1:7001/public/_nft/",
      uid: "",
      signature: "",
      show2: false,
      showbuy: false,
      showpai: false,
      id: 0,
      tid: 0,
      items: [], //售卖中商品
      auctionlist: [],
      conflux: null,
      zhuzaozhe: "",
      jimairen: "",
      no: "",
      currbizhi: "",
      thumb: "",
      currtitle: "",
      currdesc: "",
      curruid: "",
      cfuid: "", // 作品 cf_address
      address: "", // 登录者 cf_address
      currren: "", // 出价人
      price: "",
      increaseprice: "",
      paiprice: "",
      paicount: 0, // 出价次数
      endtime: 0,
    };
  },
  filters: {
    price: function (value) {
      return parseFloat(value).toFixed(2);
    },
  },
  mounted() {
    this.nft_url = this.$store.getters.nft_url;
    window.setMybalance = function (val) {};
    window.setMyearned = function (val) {};
    window.setMyprofit = function (val) {};
    window.setMyart = function (val) {};
    window.setMycoinname = function (val) {};
    window.setStakingbalance = function (val) {};
    this.screenWidth = 600; //document.body.clientWidth; // 屏幕宽
    this.init();
  },
  methods: {
    async init() {
      this.$nextTick(async function () {
        if (document.body.clientWidth >= 768) {
          this.$router.replace({ path: "/sell" });
        }
        this.address = localStorage.getItem("_address");
        this.$axios.defaults.headers.common.Authorization = this.address;
        this.uid = localStorage.getItem("nft_acc_id")
          ? localStorage.getItem("nft_acc_id")
          : "";

        /*this.$axios.get(this.$store.state.api + "/nft/selllast").then((response) => {
          this.items = response.data;
        });*/
        this.conflux = new this.$conflux({
          url: window.RPC_URL,
          networkId: 1029,
          logger: console,
        });
        this.conflux.provider = conflux;

        const SHOP_V2 = await this.conflux.Contract({
          abi: ConfluxShop_V2_ABI,
          address: ConfluxShop_V2,
        });
        //所有售卖中
        const shopids = await SHOP_V2.getShopItems();
        let tokenids = "";
        shopids.forEach(async (element) => {
          tokenids += element + ",";
        });console.log(shopids);
        this.$axios
          .get(this.$store.state.api + "/digital", {
            params: {
              ids: tokenids,
              addr: ArtNft_V2_ADDRESS,
            },
          })
          .then(async (response) => {
            this.items = response.data.rows;
            //我的售卖中
            const myshopids = await SHOP_V2.getSellerShopItems(_address);
            myshopids.forEach(async (element) => {
              if (document.getElementById("sell" + element)) {
                document.getElementById("sell" + element).style.display = "block";
              }
            });
          });

        const SHOPPAI_V2 = await this.conflux.Contract({
          abi: ConfluxShop_PaiV2_ABI,
          address: ConfluxShop_PaiV2,
        });
        const shopids_pai = await SHOPPAI_V2.getShopItems();
        let tokenids_pai = "";
        shopids_pai.forEach(async (element) => {
          tokenids_pai += element + ",";
        });
        this.$axios
          .get(this.$store.state.api + "/digital", {
            params: {
              ids: tokenids_pai,
              addr: ArtNft_V2_ADDRESS,
            },
          })
          .then(async (response) => {
            this.auctionlist = response.data.rows;
            //我的拍卖中
            const mypaishopids = await SHOPPAI_V2.getSellerShopItems(_address);
            mypaishopids.forEach(async (element) => {
              if (document.getElementById("pai" + element)) {
                document.getElementById("pai" + element).style.display = "block";
              }
            });
          });
      });
    },
    // 显示购买信息
    async showbuyy(id, zhuzaozhe, tid, tit, img, picpath, price, desc, signature) {
      const _address = await window.getAddress();

      this.thumb = this.nft_url + picpath + "/" + img.replace("/home/xvx/www/", "");
      this.currtitle = tit;
      this.currdesc = desc;
      this.signature = signature;
      this.price = price;
      this.tid = tid;
      this.id = id;

      //this.currbizhi = stock;
      try {
        this.zhuzaozhe =
          zhuzaozhe.substring(0, 6) + "..." + zhuzaozhe.substring(40, zhuzaozhe.lenght);
      } catch (error) {}

      const SHOP_V2 = await this.conflux.Contract({
        abi: ConfluxShop_V2_ABI,
        address: ConfluxShop_V2,
      });
      const shopids = await SHOP_V2.getShopItems(); // 所有售卖中
      const shopids_arr = (shopids + "").split(","); // 转成数组
      const index = shopids_arr.map((item) => item).indexOf(tid + ""); // 获得index
      const item = await SHOP_V2.getShopItems_at(index, tid); // 此NFT的信息 (为了同步数据使用)
      //console.log(item);
      const amount = item[2];
      this.currbizhi = +amount; // 币值 块

      const shopitem = await SHOP_V2.shopMapNew(ArtNft_V2_ADDRESS, tid); // 售卖中的信息
      //console.log(shopitem[0]);
      if (shopitem[0] === _address) {
        return;
      }
      this.showbuy = true;
      this.price = Drip(shopitem[1]).toCFX();

      //设置价格
      this.$axios
        .put(this.$store.state.api + "/digital/" + id, {
          price: +this.price,
        })
        .then((response) => {
          this.init();
        });
    },
    // 显示拍卖信息
    async showpaii(id, author, tid, tit, img, picpath, desc, signature) {
      const _address = await window.getAddress();

      this.tid = tid;
      this.id = id;

      this.thumb = this.nft_url + picpath + "/" + img.replace("/home/xvx/www/", "");

      const SHOPPAI_V2 = await this.conflux.Contract({
        abi: ConfluxShop_PaiV2_ABI,
        address: ConfluxShop_PaiV2,
      });

      const pai = await SHOPPAI_V2.shopMapNew(ArtNft_V2_ADDRESS, tid); // shopMapNew
      //const buyer = pai[2];

      const jimairen = pai[0]; //卖家
      if (jimairen === _address) {
        return;
      }
      this.showpai = true;
      const price = Drip(pai[1]).toCFX(); //价格
      console.log(pai[2]); //xxx买家 无用
      const increaseprice = Drip(pai[3]).toCFX(); //最小加价额度
      const paicount = +pai[4]; //0没人买、1有人出价
      const endtime = +pai[5]; //拍卖结束unix时间戳
      const bizhi = +pai[6]; //nft上架数量

      this.jimairen =
        jimairen.substring(0, 6) + "..." + jimairen.substring(40, jimairen.lenght);
      this.paicount = paicount;
      this.cfuid = paicount ? pai[2] : "";

      this.zhuzaozhe =
        author.substring(0, 6) + "..." + author.substring(40, author.lenght);
      this.currtitle = tit;

      this.currdesc = desc;
      this.signature = signature;
      this.price = price;
      this.increaseprice = increaseprice;

      this.currbizhi = bizhi;

      this.endtime = endtime;

      // 设置拍卖
      this.$axios
        .put(this.$store.state.api + "/digital/" + this.id, {
          pai_price: +price,
        })
        .then((response) => {
          this.init();
        });
    },
    // 关闭拍卖信息
    closespai() {
      this.zhuzaozhe = "";
      this.showpai = false;
    },
    // 关闭购买信息
    closes() {
      this.zhuzaozhe = "";
      this.showbuy = false;
    },
    // 购买产品
    async buyy() {
      const price = this.price;
      let tid = this.tid;
      const uid = this.uid; //  收购者

      const _address = await window.getAddress();

      tid = +tid;

      // 授权
      const ArtNFT_V2 = await this.conflux.Contract({
        abi: ArtNft_V2_ABI,
        address: ArtNft_V2_ADDRESS,
      });
      const is = await ArtNFT_V2.isApprovedForAll(_address, ConfluxShop_V2);
      if (!is) {
        const collateral1 = await ArtNFT_V2.setApprovalForAll(
          ConfluxShop_V2,
          true
        ).estimateGasAndCollateral({ from: _address });
        await ArtNFT_V2.setApprovalForAll(ConfluxShop_V2, true)
          .sendTransaction({
            from: _address,
            gas: collateral1.gasLimit,
            storageLimit: collateral1.storageCollateralized,
          })
          .executed();
      }

      this.showbuy = false;
      this.show2 = true;
      try {
      } catch (error) {
        console.log(error);
        this.showbuy = false;
        this.show2 = false;
      }
      const SHOP_V2 = await this.conflux.Contract({
        abi: ConfluxShop_V2_ABI,
        address: ConfluxShop_V2,
      });

      // 获取 index
      const shopids = await SHOP_V2.getShopItems();
      const shopids_arr = (shopids + "").split(",");
      const index = shopids_arr.map((item) => item).indexOf(tid + "");

      // 购买
      const par = await SHOP_V2.getShopItems_at(index, tid);
      console.log(par);

      //const tokenid = await SHOP_V2.shopIds(3);
      //const addr = await SHOP_V2.shopIds_addr(1);
      //const amount = await SHOP_V2.shopIds_amount(1);

      const amount = par[2];
      //console.log(addr);
      //console.log(amount);
      let collateral = await SHOP_V2.buySellerTicket(
        tid,
        +amount,
        ArtNft_V2_ADDRESS
      ).estimateGasAndCollateral({
        from: window._address,
        value: +this.$drip.fromCFX(price),
      });

      const buy = await SHOP_V2.buySellerTicket(tid, +amount, ArtNft_V2_ADDRESS)
        .sendTransaction({
          gas: collateral.gasLimit,
          storageLimit: collateral.storageCollateralized,
          from: window._address,
          value: +this.$drip.fromCFX(price),
        })
        .executed();

      // 设置购买
      this.$axios
          .put(this.$store.state.api + "/digital/" + this.id, {
            address: window._address,
          })
          .then((response) => {
            this.$router.push({ path: "/my" });
          });
  

      this.showbuy = false;
      this.show2 = false;
    },
    // 拍卖产品
    async pai() {
      if (!this.paiprice) {
        alert("Please enter the bidding price");
        return;
      }

      if (this.paicount === 0) {
        if (this.paiprice < this.price) {
          alert("Please enter a price greater than or equal to the current price");
          return;
        }
      } else {
        if (this.paiprice < (+this.price + +this.increaseprice)) {
          alert("The input price is too small");
          return;
        }
      }

      const _address = await window.getAddress();

      this.showpai = false;
      this.showbuy = false;
      this.show2 = true;

      const price = this.paiprice;
      let tid = this.tid;
      const uid = this.uid; //  收购者
      //console.log(tid);
      //console.log(price);

      try {
        const SHOPPAI_V2 = await this.conflux.Contract({
          abi: ConfluxShop_PaiV2_ABI,
          address: ConfluxShop_PaiV2,
        });

        // 获取 index
        const shopids = await SHOPPAI_V2.getShopItems();
        const shopids_arr = (shopids + "").split(",");
        const index = shopids_arr.map((item) => item).indexOf(tid + "");
        const par = await SHOPPAI_V2.getShopItems_at(index, +tid);

        const amount = par[2];

        let collateral = await SHOPPAI_V2.Bid_or_retrieve_NFT(
          +tid, //NFT ID号
          amount, //NFT 数量
          ArtNft_V2_ADDRESS //NFT 的合约地址
        ).estimateGasAndCollateral({
          from: _address,
          value: +this.$drip.fromCFX(price),
        });

        const pai = await SHOPPAI_V2.Bid_or_retrieve_NFT(
          +tid, //NFT ID号
          amount, //NFT 数量
          ArtNft_V2_ADDRESS //NFT 的合约地址
        )
          .sendTransaction({
            gas: collateral.gasLimit,
            storageLimit: collateral.storageCollateralized,
            value: +this.$drip.fromCFX(price),
            from: window._address,
          })
          .executed();

        // 设置拍卖
        this.$axios
          .put(this.$store.state.api + "/digital/" + this.id, {
            address: "",
            pai_address: _address,
            pai_price: +price
          })
          .then((response) => {
            this.init();
          });

        this.showpai = false;
        this.showbuy = false;
        this.show2 = false;
      } catch (error) {
        console.log(error);
        this.showbuy = false;
        this.show2 = false;
        this.init();
      }
      
    },
    // 取回拍卖
    async getauction() {
      // 是否到期 xxxxxxxx

      this.show2 = true;
      this.show = false;

      const tid = +this.tid;
      console.log(this.tid);

      const _address = await getAddress();

      const SHOPPAI_V2 = await this.conflux.Contract({
        abi: ConfluxShop_PaiV2_ABI,
        address: ConfluxShop_PaiV2,
      });

      // 获取 index
      const shopids = await SHOPPAI_V2.getShopItems();
      const shopids_arr = (shopids + "").split(",");
      const index = shopids_arr.map((item) => item).indexOf(tid + "");
      const par = await SHOPPAI_V2.getShopItems_at(index, +tid);

      const amount = par[2];
      try {
        let collateral = await SHOPPAI_V2.Bid_or_retrieve_NFT(
          +tid, //NFT ID号
          amount, //NFT 数量
          ArtNft_V2_ADDRESS //NFT 的合约地址
        ).estimateGasAndCollateral({
          from: _address,
          value: 0,
        });
        const pai = await SHOPPAI_V2.Bid_or_retrieve_NFT(
          +tid, //NFT ID号
          amount, //NFT 数量
          ArtNft_V2_ADDRESS //NFT 的合约地址
        )
          .sendTransaction({
            gas: collateral.gasLimit,
            storageLimit: collateral.storageCollateralized,
            value: 0,
            from: _address,
          })
          .executed();

        this.$axios.defaults.headers.common.Authorization = localStorage.getItem(
          "_address"
        );
        this.$axios
          .put(
            this.$store.state.api + "/fetchauction",
            this.$qs.stringify({
              id: this.tid,
            })
          )
          .then(async (response) => {
            this.showpai = false;
            this.init();
          });
      } catch (error) {
        console.log(error);
        if (error.message.indexOf("have been pai")) {
          this.$toast.fail(
            "The auction has been successful, waiting for the user to retrieve it"
          );
        } // 已经被拍
        else {
          this.show2 = false;
          this.$toast.fail("During the auction, it cannot be retrieved");
        }
      }
      this.show2 = false;
    },
    // 全部一口价
    goAll() {
      this.$router.push({ path: "/sellall" });
    },
    goPaiAll() {
      this.$router.push({ path: "/pai/all" });
    },
    goPaiFinish() {
      this.$router.push({ path: "/paiok/all" });
    },
  },
};
